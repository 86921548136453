
// mui
import { Container, Divider, Stack } from "@mui/material";
import Box from '@mui/material/Box';

// @snippets
import TeamGallery from "@snippets/TeamGallery";

// extra
import RequestForm from '@views/contacts/RequestForm'

// blocks
import Header from './Header'
import Partners from './Partners'
import UnrealEngine from './UnrealEngine'
import Services from './Services'
import Technologies from './Technologies'
import CallToAction from './CallToAction'
import Expertise from './Expertise'
import Press from './Press'
import Articles from './Articles'
import Portfolio from './Portfolio'


export default function View() {


  return (
    <Box>

      <Box>
        <Header />
      </Box>

      <Container maxWidth="xl">

        <Box sx={{
          py: 15,
        }}>
          <Partners />
        </Box>

      </Container>

      <Box sx={{
        bgcolor: 'background.paper',
        py: {
          xs: 10,
          md: 15,
        },
      }}>
        <Container maxWidth="xl">
          <UnrealEngine />
        </Container>

        <Box sx={{
          py: 15,
        }}>
          <Divider />
        </Box>

        <Container maxWidth="xl">
          <Services />
        </Container>
      </Box>

      <Box sx={{
        bgcolor: 'background.default',
        py: {
          xs: 10,
          md: 15,
        },
      }}>
        <Container maxWidth="xl">
          <Technologies />
        </Container>
      </Box>

      <Box sx={{
        position: 'relative',
      }}>
        <Container maxWidth="xl" sx={{
          position: 'relative',
          zIndex: 1,
        }}>
          <CallToAction />
        </Container>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          height: '50%',
        }} />
      </Box>

      <Box sx={{
        bgcolor: 'background.paper',
        pt: 10,
        pb: {
          xs: 15,
          md: 25,
        },
      }}>
        <Container maxWidth="xl">
          <Expertise />
        </Container>
      </Box>

      <Portfolio />

      <Box sx={{
        bgcolor: 'background.default',
        py: {
          xs: 10,
          md: 25,
        },
      }}>
        <Container maxWidth="xl">
          <Stack spacing={20}>
            <Press />
            {/* <Articles /> */}
          </Stack>
        </Container>
      </Box>

      <Box sx={{
        bgcolor: 'background.paper',
        py: {
          xs: 10,
          md: 25,
        },
      }}>
        <Container maxWidth="xl">
          <RequestForm />
        </Container>
      </Box>
      <Box sx={{
        // bgcolor: 'background.paper',
        pt: 15,
        borderTop: '1px solid',
        borderColor: 'divider',
      }}>
        <Container maxWidth="xl">
          <TeamGallery />
        </Container>
      </Box>



    </Box>
  )
}