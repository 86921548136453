
// @configs
import project from "@configs/project";
import routes from "@configs/routes";

// mui
import { Stack, Typography } from "@mui/material";
import Box from '@mui/material/Box';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// @components
import { ButtonLink } from "@components/Link";

// blocks
import Forbes from "./Forbes";


export default function Press() {

  const logotypes = [
    project.assets.logotypes.lv80,
    project.assets.logotypes.coinTelegraph,
    project.assets.logotypes.forbes,
    project.assets.logotypes.vc,
  ]

  return (
    <Stack spacing={{
      xs: 5,
      md: 15,
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 5,
      }}>
        <Typography variant="h2">
          We are in the press.
        </Typography>
        <ButtonLink
          href={routes.PAGES.CONTACTS}
          endIcon={<ArrowForwardIcon />}
          variant="outlined"
          color="inherit">
          Press enquiries
        </ButtonLink>
      </Box>

      <Forbes />

      <Box sx={{
        bgcolor: 'background.paper',
        borderRadius: 2,

        p: {
          xs: 5,
          md: 10,
        },
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(130px, 1fr))',
        gap: 5,
      }}>
        {logotypes.map((logotype, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box
              component="img"
              src={logotype}
              sx={{
                height: {
                  xs: 35,
                  sm: 80,
                },
              }}
            />
          </Box>
        ))}
      </Box>

    </Stack>
  );
}