import { useState, useEffect, useRef } from "react";

// @configs
import project from "@configs/project";

// @hooks
import useMedia from "@hooks/useMedia";

// mui
import { CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';

// libs
import { InView } from 'react-intersection-observer';


// @components
// import InView from '@components/InView';
import VideoPlayer, { VideoPlayerRef } from '@components/VideoPlayer';

// snippets
import PlayButton from './PlayButton'


export interface VideoControlsState {
  play: boolean,
  loaded: boolean,
  wait: boolean,
  buffering: boolean,
}

export default function VideoBlock() {

  // refs
  const playerRef = useRef<VideoPlayerRef>(null);

  // states
  const [mounted, setMounted] = useState(false)

  const [silent, setSilent] = useState(true)
  const [status, setStatus] = useState<VideoControlsState>({
    play: false,
    loaded: false,
    wait: false,
    buffering: false,
  })
  const [visible, setVisible] = useState(false)

  // hooks
  const media = useMedia()

  // vars
  const videoDesktop = project.assets.mainVideo.desktop
  const videoMobile = project.assets.mainVideo.mobile

  const handlePlay = () => {
    const player = playerRef.current
    if (!player) return

    setSilent(false)

    setTimeout(() => {
      if (!silent && status.play) {
        player.pause();
        setStatus(c => ({ ...c, play: false }))
      } else {
        player.play();
        setStatus(c => ({ ...c, play: true }))
      }
    }, 100)
  };

  useEffect(() => {
    setMounted(true)
  }, [])

  // const renderVideoGradient = (direction: 'left' | 'right') => {

  //   return (
  //     <Box sx={{
  //       pointerEvents: 'none',
  //       background: `linear-gradient(${direction === 'right' ? '-' : ''}90deg, ${settings.bgColor(1)}, ${settings.bgColor(0)} 100%)`,
  //       // bgcolor: 'red',
  //       zIndex: 1,
  //       position: 'absolute',
  //       left: direction === 'left' ? 0 : undefined,
  //       right: direction === 'right' ? 0 : undefined,
  //       width: 70,
  //       bottom: 0,
  //       height: '100%',
  //     }} />
  //   )
  // }

  const renderBuffering = (size: number) => {
    if (silent === true || status.buffering === false || status.play === true) return null

    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // bgcolor: 'rgba(0,0,0,0.5)',
          zIndex: 1,
        }}
      >
        <CircularProgress
          size={size}
          thickness={2}
          color="primary"
          sx={{
            color: 'common.white',
          }}
        />
      </Box>
    )
  }

  if (!mounted) return null

  let videoConfig: any = {
    loop: true,
    playing: true,
    muted: true,
    url: media.down.md ? videoMobile.videoSilent : videoDesktop.videoSilent,
  }
  if (!silent) {
    videoConfig = {
      loop: false,
      playing: false,
      muted: false,
      url: media.down.md ? videoMobile.video : videoDesktop.video,
    }
  }

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
    }}>

      {(silent || !status.play) && (
        <PlayButton
          isReady={status.loaded}
          handlePlay={handlePlay} />
      )}

      {media.down.md ? (
        <Box
          onClick={handlePlay}
          sx={{
            width: '100%',
          }}
        >

          {renderBuffering(50)}

          <InView onChange={(inView) => setVisible(inView)}>
            <VideoPlayer
              ref={playerRef}
              width="100%"
              height="100%"
              playsinline
              controls={false}
              {...videoConfig}
              poster={videoMobile.preview}
              onReady={() => {
                setStatus(c => ({ ...c, loaded: true }))
              }}
              onPlay={() => {
                setStatus(c => ({ ...c, play: true }))
              }}
              onPause={() => {
                setStatus(c => ({ ...c, play: false }))
              }}
              onEnded={() => {
                setStatus(c => ({ ...c, play: false }))
                setSilent(true)
              }}
              onBuffer={(isBuffering: boolean) => {
                setStatus(c => ({ ...c, buffering: isBuffering }))
              }}
              playing={visible}
              sx={{
                pointerEvents: 'none',
              }}
            />
          </InView>
        </Box>
      ) : (
        <Box
          sx={{
            pointerEvents: 'none',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'flex-end',
          }}>

          {renderBuffering(100)}

          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              maskImage: "linear-gradient(to right, transparent, black 10%, black 90%, transparent)",
            }}
          >
            <InView onChange={(inView) => setVisible(inView)}>
              <VideoPlayer
                ref={playerRef}
                width="100%"
                height="100%"
                {...videoConfig}
                controls={false}
                poster={videoDesktop.preview}
                onReady={() => {
                  setStatus(c => ({ ...c, loaded: true }))
                }}
                onPlay={() => {
                  setStatus(c => ({ ...c, play: true }))
                }}
                onPause={() => {
                  setStatus(c => ({ ...c, play: false }))
                }}
                onEnded={() => {
                  setStatus(c => ({ ...c, play: false }))
                  setSilent(true)
                }}
                onBuffer={(isBuffering: boolean) => {
                  setStatus(c => ({ ...c, buffering: isBuffering }))
                }}
                playing={visible}
                sx={{
                  maskImage: "linear-gradient(to bottom, transparent, black 10%, black 100%)",
                }}
              />
            </InView>
          </Box>

          <Box
            onClick={handlePlay}
            sx={{
              pointerEvents: 'all',
              position: 'absolute',
              zIndex: 1,
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              cursor: 'pointer',
            }} />
        </Box>
      )}
    </Box>
  )
}