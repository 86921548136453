import { cfg } from './configs'

// libs
import axios from 'axios'

// types
import type { StemmerOptions } from './types'

class Stemmer {
  static async stem(resolvedUrl: string, text: string, options?: StemmerOptions) {
    const stemUrl = `${cfg.host}/api/stem`
    const { data } = await axios.post(stemUrl, { resolvedUrl, text, options })
    return data?.result || text
  }
}

export default Stemmer