
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

interface Props extends BoxProps {
  children: React.ReactNode | string
}
export default function RenderHtml(props: Props) {

  const isDangerous = typeof props.children === 'string';

  const extraStyles = {
    mx: 0,
    my: 2,
    p: 0,
  }

  const boxStyles = {
    '& h1': {
      typography: 'h1',
      ...extraStyles,
    },
    '& h2': {
      typography: 'h2',
      ...extraStyles,
    },
    '& h3': {
      typography: 'h3',
      ...extraStyles,
    },
    '& h4': {
      typography: 'h4',
      ...extraStyles,
    },
    '& h5': {
      typography: 'h5',
      ...extraStyles,
    },
    '& h6': {
      typography: 'h6',
      ...extraStyles,
    },
    '& p': {
      typography: 'body1',
    },
    '& a': {
      color: 'error.main',
      textDecoration: 'none',
    },
    ...props.sx,
  }

  // return (
  //   <pre>
  //     {JSON.stringify(props.children, null, 2)}
  //   </pre>
  // )

  if (isDangerous) {
    return (
      <Box sx={boxStyles}
        dangerouslySetInnerHTML={{
          __html: props.children as string
        }}
      />
    )
  }

  return (
    <Box sx={boxStyles}>
      {props.children}
    </Box>
  )
}