
// libs
import anime from 'animejs';
import { InView } from "react-intersection-observer";

// @hooks
import useMedia from '@hooks/useMedia';

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// @components
import IconifyIcon from "@components/IconifyIcon";

// @data
import { webDevSolutionsList } from '@data/index'


export default function Services() {

  // hooks
  const media = useMedia()

  const animateCards = () => {
    anime({
      targets: '.service-card',
      translateY: [-100, 0],
      opacity: [0, 1],
      delay: anime.stagger(100),
      easing: 'easeOutQuad',
    })
  }

  const maxItems = 4 * 2
  const list = webDevSolutionsList.filter((item, index) => index < maxItems)
  return (
    <InView
      onChange={(visible: boolean) => {
        if (visible) {
          animateCards()
        }
      }}
    >
      <Box>
        <Typography variant="h1">
          From Concept to Completion:
          <br />
          Our Full-Stack Expertise.
        </Typography>
        <Box sx={{
          mt: 10,
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          },
          gap: 10,
        }}>
          {list.map((service, index) => {
            const title = service.title
            const titleList = service.title.split('\n')

            return (
              <Box key={index} className="service-card">
                <Box sx={{
                  mb: 2,
                  '& svg': {
                    fontSize: '3rem',
                    color: 'primary.light'
                  }
                }}>
                  <IconifyIcon icon={service.icon} />
                </Box>
                <Box>
                  <Typography variant="h4" sx={{
                    mb: 2,
                  }}>
                    {media.up.md ? titleList.map((item, index) => (
                      <Box key={index}>
                        {item}
                      </Box>
                    )) : title}
                  </Typography>
                  <Typography variant="body1">
                    {service.description}
                  </Typography>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </InView>
  )
}