import React, { forwardRef, useRef, useEffect, useState, useImperativeHandle, use } from 'react';

// libs
import ReactPlayer from 'react-player';
import type { ReactPlayerProps, Config } from 'react-player';

// mui
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


interface VideoPlayerProps extends Omit<ReactPlayerProps, 'onBuffer'> {
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  onReady?: () => void;
  onBuffer?: (buffering: boolean) => void;
  sx?: SxProps;
  url: string | string[];

  playing?: boolean;
  showProgress?: boolean;
}

export interface VideoPlayerRef {
  play: () => void;
  pause: () => void;
  // isPlaying: boolean;
  // isPaused: boolean;
  // isEnded: boolean;
}

type Status = 'playing' | 'paused' | 'ended' | 'idle';

const VideoPlayer: React.ForwardRefRenderFunction<VideoPlayerRef, VideoPlayerProps> = (
  { onPlay, onPause, onEnded, onReady, onBuffer, sx, ...props }, ref
) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const [mounted, setMounted] = useState(false)

  const [status, setStatus] = useState<Status>('idle');
  const [isBuffering, setIsBuffering] = useState(false);

  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  // Ref forwarding
  useImperativeHandle(ref, () => methods);

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (!mounted) return

    if (props.playing) {
      methods.play()
    } else {
      methods.pause()
    }
  }, [mounted, props.playing])


  const handlePlay = () => {
    setStatus('playing')
    setIsBuffering(false)

    if (onPlay) {
      onPlay();
    }

    if (onBuffer) {
      onBuffer(false);
    }
  };

  const handleBuffer = () => {
    setIsBuffering(true)

    if (onBuffer) {
      onBuffer(true);
    }
  }

  const handlePause = () => {
    setStatus('paused')
    setIsBuffering(false)

    if (onPause) {
      onPause();
    }
  };

  const handleEnded = () => {
    setStatus('ended')

    if (onEnded) {
      onEnded();
    }
  };

  const handleReady = () => {
    if (props.playing) {
      methods.play()
    }

    if (onReady) {
      onReady();
    }
  };


  // computed
  const methods = new class {
    get player() {
      return playerRef.current?.getInternalPlayer() as HTMLVideoElement
    }
    play() {
      if (!this.player) return
      try {
        this.player.play();
      } catch (error) {
        console.warn(error)
      }
    }
    pause() {
      if (!this.player) return
      try {
        this.player.pause();
      } catch (error) {
        console.warn(error)
      }
    }
  } as VideoPlayerRef

  if (!mounted) {
    return (
      <Box
        component="video"
        width="100%"
        height="100%"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      >
        <source src={props.url as string} type="video/mp4" />
      </Box>
    )
  }

  const renderProgress = () => {
    if (!props.showProgress || status !== 'playing') {
      return null
    }

    const value = (progress && duration) ? ((progress / duration) * 100) : 0

    return (
      <Box sx={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        pointerEvents: 'none',
        color: 'common.white',
        zIndex: (theme: any) => theme.zIndex.appBar,
        width: '100%',
      }}>

        <LinearProgress
          value={value}
          variant="determinate"
          color="inherit"
          sx={{
            bgcolor: 'rgba(0, 0, 0, .6)',
            height: 5,
            // [`& .${linearProgressClasses.root}`]: {

            // },
            [`& .${linearProgressClasses.bar}`]: {
              transitionDuration: '1s'
            },
          }}
        />

      </Box>
    )
  }


  return (
    <Box sx={{
      width: '100%',
    }}>

      {renderProgress()}

      <Box sx={{
        ...sx as SxProps,
        position: 'relative',
        lineHeight: 0,
        width: '100%',
      }}>

        <ReactPlayer
          ref={playerRef}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleEnded}
          onReady={handleReady}
          onBuffer={handleBuffer}
          onProgress={({ playedSeconds }) => {
            setProgress(playedSeconds)
          }}
          onDuration={(duration) => {
            setDuration(duration)
          }}
          {...props}
          style={props.style}
          controls={props.controls || false}
          config={{
            file: {
              attributes: {
                poster: props.poster,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default forwardRef(VideoPlayer)
