// @configs
import routes from "@configs/routes";

const urlBuilder = (url: string) => `${cfg.host}${url}`


export const cfg = {
  density: 1000, // 1 in *** words will be linked
  maxSimilarLinks: 2, // max number of similar links to show
  host: process.env.NEXTAUTH_URL,
}



// Define a type for the stemDictionary
type StemDictionary = {
  category: string;
  keywords: string[];
  link: string;
}

// Create an array of objects representing keywords and their corresponding links
export const stemDictionary: StemDictionary[] = [
  // {
  //   keywords: ['run', 'running', 'runner'],
  //   link: 'https://example.com/running',
  // },
  // {
  //   keywords: ['matrix'],
  //   link: 'https://example.com/matrix',
  // },
  // {
  //   keywords: ['business'],
  //   link: 'https://example.com/how-are-you',
  // },


  // Default
  {
    category: 'default',
    keywords: ['software', 'developers', 'development', 'developer'],
    link: urlBuilder(routes.PAGES.HOME),
  },
  {
    category: 'default',
    keywords: ['unrealos'],
    link: urlBuilder(routes.PAGES.ABOUT),
  },

  // Web Development
  {
    category: 'web',
    keywords: ['services', 'service', 'technologies', 'technology'],
    link: urlBuilder(routes.TECHNOLOGIES.INDEX),
  },
  {
    category: 'web',
    keywords: ['solution', 'solutions'],
    link: urlBuilder(routes.SOLUTIONS.INDEX),
  },

  // Unreal Engine
  {
    category: 'ue',
    keywords: ['unreal engine', 'unreal', 'ue4', 'ue5'],
    link: urlBuilder(routes.SOLUTIONS.UNREAL_ENGINE.INDEX),
  },
  {
    category: 'ue',
    keywords: ['metaeditor', 'pixel streaming', 'pixelstreaming', 'meta editor'],
    link: urlBuilder(routes.SOLUTIONS.UNREAL_ENGINE.PIXEL_STREAMING),
  },
  {
    category: 'ue',
    keywords: ['auto', 'automobile'],
    link: urlBuilder(routes.SOLUTIONS.UNREAL_ENGINE.INDUSTRIES.AUTOMOTIVE),
  },
  {
    category: 'ue',
    keywords: ['education', 'teach', 'learn', 'school', 'university', 'college'],
    link: urlBuilder(routes.SOLUTIONS.UNREAL_ENGINE.INDUSTRIES.EDUCATION),
  },
  {
    category: 'ue',
    keywords: ['property', 'real estate'],
    link: urlBuilder(routes.SOLUTIONS.UNREAL_ENGINE.INDUSTRIES.REAL_ESTATE),
  },
  {
    category: 'ue',
    keywords: ['metaverse', 'virtual world', 'virtual reality', 'augmented'],
    link: urlBuilder(routes.SOLUTIONS.UNREAL_ENGINE.INDUSTRIES.METAVARSES),
  }
]
