import { useEffect } from "react";

// @configs
import routes from "@configs/routes";

// @hooks
import useMedia from "@hooks/useMedia";

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// @components
import IconifyIcon from "@components/IconifyIcon";
import { ButtonLink } from "@components/Link";

// libs
import anime from 'animejs';

// @data
import { technologiesList } from "@data/technologies";


export default function Technologies() {
  // hooks
  const media = useMedia()

  const animateCard = (card: HTMLElement, delay: number) => {
    anime({
      targets: card,
      translateY: [anime.random(-50, 50), 0, anime.random(-50, 50)],
      scale: [0.85, 1, 0.85],
      opacity: [0, 1, 0],
      easing: 'easeInSine',
      direction: 'alternate',
      duration: anime.random(2000, 6000),

      // random end delay to avoid unnatural look
      endDelay: anime.random(100, 1000),
      delay: anime.random(0, delay),
      elasticity: 500,

      loop: true,
    });
  };

  useEffect(() => {
    // Trigger animations when the component mounts
    technologiesList.forEach((_, index) => {
      const card = document.getElementById(`technology-card-${index}`);
      if (!card) return;
      animateCard(card, index * 100); // Add a delay for staggered animation
    });
  }, []);

  const maxItems = media.down.sm ? 4 * 3 : 4 * 4
  const list = technologiesList.filter((_, index) => index < maxItems)

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      gap: 10,
    }}>
      <Box sx={{
        width: {
          xs: '100%',
          md: '33%',
        },
      }}>
        <Typography variant="h1" gutterBottom>
          What’s Your Stack?
        </Typography>
        <Typography variant="body1">
          Our team has expertise in almost
          every programming language.
        </Typography>
        <Box sx={{
          pt: 10
        }}>
          <ButtonLink
            href={routes.TECHNOLOGIES.INDEX}
            endIcon={<ChevronRightIcon />}
            variant="outlined" size="large">
            Our All technologies
          </ButtonLink>
        </Box>
      </Box>

      <Box sx={{
        flexGrow: 1,
        width: {
          xs: '100%',
          md: 'auto',
        },
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)',
        },
        gap: 5,
      }}>
        {list.map((service, index) => (
          <Box key={index}
            id={`technology-card-${index}`} // Add an ID for targeting in animations
            sx={{
              bgcolor: 'background.paper',
              borderRadius: 2,
              p: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box sx={{
              mb: 2,
              '& svg': {
                fontSize: '2rem',
                maxWidth: 70,
                color: 'primary.light'
              }
            }}>
              <IconifyIcon icon={service.icon} />
            </Box>
            <Box>
              <Typography variant="h6">
                {service.title}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}