
// @configs
import project from "@configs/project";

// types
import type { TechnologySlug } from './technologies'

export interface PortfolioItem {
  available: boolean;
  slug: string;
  title: string;
  description: string;
  technologies: TechnologySlug[];
  videoUrl: string | false;
}

export const portfolioList: PortfolioItem[] = [
  {
    available: false,
    slug: 'wixi',
    title: 'Utility Token',
    technologies: ['reactjs', 'nextjs', 'typescript', 'mui', 'vercel'],
    description: 'A unique crypto ecosystem for traders, merchants, and payment systems.',
    videoUrl: project.assets.videos.wixi,
  },
  {
    available: true,
    slug: 'telesite',
    title: 'Telesite',
    technologies: ['reactjs', 'nextjs', 'typescript', 'mui', 'postgresql', 'vercel'],
    description: 'Telesite is a visual website builder that uses artificial intelligence to create websites.',
    videoUrl: project.assets.videos.telesite,
  },
  {
    available: false,
    title: "MetaEditor",
    description: "Open source solution: a complete set of tools for professional development and running of Unreal Engine applications in browsers.",
    slug: "metaeditor",
    technologies: ['reactjs', 'nextjs', 'typescript', 'mui', 'postgresql', 'vercel'],
    videoUrl: false,
  },
  {
    available: false,
    title: "Real Estate",
    description: "The largest real estate aggregator in the Caribbean islands – your gateway to finding the perfect home, vacation rental, or premium land for investment.",
    slug: "real-esatte",
    technologies: ['reactjs', 'nextjs', 'typescript', 'mui', 'postgresql', 'vercel'],
    videoUrl: false,
  },
  {
    available: false,
    title: 'Online Casino',
    description: 'iGaming platform with in-house games. Development of games and game mathematics, creation of a loyalty program and tools for maintaining the platform.',
    slug: 'casino',
    technologies: ['reactjs', 'nextjs', 'typescript', 'mui', 'postgresql', 'vercel'],
    videoUrl: project.assets.videos.metatokens,
  },
  {
    available: false,
    title: 'Audi',
    description: 'Pixel Streaming technology for Audi. Development of a web application for viewing 3D models of cars in a browser.',
    slug: 'toronto',
    technologies: ['unrealengine', 'pixel-streaming', 'metaeditor', 'reactjs'],
    videoUrl: project.assets.videos.metaeditor,
  },
  {
    available: false,
    title: 'Toronto',
    description: 'Development of a highly realistic digital copy of Toronto based on Unreal Engine 5. Creation of interactive tools for viewing real estate objects.',
    slug: 'toronto',
    technologies: ['unrealengine', 'pixel-streaming', 'metaeditor', 'reactjs'],
    videoUrl: project.assets.videos.toronto,
  },
]

