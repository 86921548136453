import { useEffect, useRef, useState } from "react";

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// libs
import { useInView } from "react-intersection-observer";

// data
import { portfolioList } from "@data/portfolio";

// @components
import VideoPlayer, { VideoPlayerRef } from "@components/VideoPlayer";

interface CardsProps {
  visible: boolean
}

const Cards = ({ visible }: CardsProps) => {

  const playerRef = useRef<VideoPlayerRef>(null)

  const list = portfolioList.filter((item, index) => item.videoUrl)

  return (
    <Box sx={{
      display: "flex",
      gap: 10,
    }}>
      <Box sx={{
        width: 0,
        flexShrink: 0,
      }} />
      {list.map((card, index) => (
        <Box key={index} sx={{
          bgcolor: 'grey.900',
          borderRadius: 2,
          overflow: 'hidden',
          boxShadow: '0px 4px 10px rgba(0,0,0,.1)',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          minWidth: 700,
        }}>
          <Box sx={{
            p: 3,
          }}>
            <Typography variant="h6" align="center"
              sx={{
                textShadow: '1px 1px 0px rgba(0,0,0,.5)',
                color: 'rgba(255,255,255,.8)',
                textTransform: 'uppercase'
              }}>
              {card.title}
            </Typography>
          </Box>
          <Box sx={{
            bgcolor: 'customColors.overlay',
            flexGrow: 1,
            lineHeight: 0,
            height: 400,
          }}>

            <VideoPlayer
              ref={playerRef}
              url={card.videoUrl}
              width="100%"
              height="100%"
              playsinline
              controls={false}
              playing={visible}
              loop
              muted
              showProgress
              // poster={videoPreviewXs}
              // onPlay={() => setIsPlaying(true)}
              // onPause={() => setIsPlaying(false)}
              // onEnded={() => setIsPlaying(false)}
              sx={{
                pointerEvents: 'none',
                // maskImage: 'linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%)',
              }}
            />

            {/* <Box
              component="img"
              src={card.image}
              sx={{
                borderRadius: 2,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,

                height: '60vh',
              }} /> */}
          </Box>
        </Box>
      ))}
      <Box sx={{
        width: '1px',
        flexShrink: 0,
      }} />
    </Box>
  )
}



export default function SimpleGallery() {

  // refs
  const scrollRef = useRef<HTMLDivElement>(null);

  // hooks
  const [inViewRef, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
    delay: 100,
  });

  // states
  const [windowScoll, setWindowScroll] = useState(0);

  // Detect scroll direction by window
  useEffect(() => {

    const handleWindowScroll = () => {
      if (!inView) return;

      const elementScrollLeft = scrollRef.current?.scrollLeft || 0;

      setWindowScroll(c => {
        const direction = window.scrollY - c;

        const shift = 5
        const moveLeft = direction > 0 ? elementScrollLeft + shift : elementScrollLeft - shift;
        scrollRef.current?.scrollTo({
          left: moveLeft
          // behavior: 'smooth',
        })

        return window.scrollY;
      })


    }

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }

  }, [inView]);


  return (
    <Box>
      <Box ref={inViewRef} sx={{
        bgcolor: 'grey.300',
        boxShadow: 'inset 0px 4px 10px rgba(0,0,0,.1)'
      }}>
        <Box
          ref={scrollRef}
          sx={{
            overflowX: "auto",
            py: 10,
          }}
        >
          <Box>
            <Cards visible={inView} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}