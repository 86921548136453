// views
import View from '@views/main'
export default View


// i18n
import { getServerSidePropsI18n, GetServerSidePropsContext } from "@core/i18n/pageService/getServerPropsI18n"
import { tDict } from '@core/i18n/useTranslate'


export const getServerSideProps = async (context: GetServerSidePropsContext) => {

  const title = 'Unrealos — Software Development Company'
  const description = 'Unrealos creates modern and reliable software solutions for AI, Metaverses, iGaming, PaaS & SaaS'

  return getServerSidePropsI18n(context,
    {
      // pageData,
      config: {
        protected: false,
        appBar: {
          position: 'absolute',
          bgcolor: 'transparent',
          noContainer: true,
        },
        footer: {
          size: 'default'
        },
        plugins: {
          chat: true,
        },
        seo: {
          title,
          description,
        },
      },
    },
    {
      namespacesRequired: ['main']
    }
  );
};
