// @configs
import routes from "@configs/routes";

// box
import { Container, Typography } from "@mui/material";
import Box from '@mui/material/Box';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// @components
import { ButtonLink } from "@components/Link";

// @snippets
import SimpleGallery from "./SimpleGallery";

export default function Portfolio() {
  return (
    <Box sx={{
      bgcolor: 'background.paper',
    }}>
      <Container maxWidth="xl">
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Typography variant="h2">
            Our Work
          </Typography>
          <ButtonLink
            href={routes.PAGES.PORTFOLIO}
            endIcon={<ArrowForwardIcon />}
            variant="outlined"
            color="inherit">
            Show all
          </ButtonLink>
        </Box>
      </Container>

      <Box sx={{
        mt: 10
      }}>
        <SimpleGallery />
      </Box>
    </Box>
  )
}