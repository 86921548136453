
// mui
import { IconButton, CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';

// icons
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


interface Props {
  handlePlay: () => void;
  isReady: boolean;
}

const PlayButton: React.FC<Props> = ({ handlePlay, isReady }) => {

  return (
    <Box sx={{
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
    }}>
      {/* <pre>
        {JSON.stringify(videoControls, null, 2)}
      </pre> */}

      <IconButton
        onClick={() => {
          handlePlay()
        }}
        sx={{
          pointerEvents: 'all',
          color: 'common.white',
          '& > svg': {
            fontSize: {
              xs: '4rem',
              sm: '6rem',
            },
          },
          '&:hover': {
            color: 'common.white',
          },
        }}>

        {isReady ? (
          <PlayCircleIcon />
        ) : (
          <CircularProgress size={30} color="inherit" />
        )}

      </IconButton>
    </Box>
  )
}

export default PlayButton