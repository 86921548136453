
// @configs
import routes from "@configs/routes";

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// @components
import IconifyIcon from "@components/IconifyIcon";
import { ButtonLink } from "@components/Link";


export default function CallToAction() {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      borderRadius: 4,
      border: '5px solid',
      borderColor: 'divider',
      boxShadow: 3,
      gap: 5,
      p: {
        xs: 5,
        md: 15,
      },
      bgcolor: 'background.paper',
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 5,
        '& svg': {
          fontSize: {
            xs: '5rem',
            // md: '5rem',
          },
        },
      }}>
        <IconifyIcon icon="bx:bx-rocket" />
        <Typography sx={{
          typography: {
            xs: 'h4',
            md: 'h3',
          },
          textAlign: {
            xs: 'center',
            md: 'left',
          },
        }}>
          Want to accelerate software
          <br />
          development at your company?
        </Typography>
      </Box>
      <Box>
        <ButtonLink
          fullWidth
          href={routes.PAGES.CONTACTS}
          size="large"
          data-button="rounded"
          variant="contained" color="success">
          Request a quote
        </ButtonLink>
      </Box>
    </Box>
  );
}