
// @hooks
import useMedia from "@hooks/useMedia";


// blocks
import Desktop from './Desktop';
import Mobile from './Mobile';

// mui
import Box from '@mui/material/Box';


export default function Intro() {

  // hooks
  const media = useMedia()

  // computed
  const title = 'Ai~Web~3D \nDevelopment \nCompany'
  const subtitle = 'Unrealos creates modern and reliable software solutions for AI, Metaverses, iGaming, PaaS & SaaS'


  const renderContent = () => {
    if (media.down.md) {
      return (
        <Mobile
          title={title}
          subtitle={subtitle}
        />
      )
    }

    return (
      <Desktop
        title={title}
        subtitle={subtitle}
      />
    )
  }

  return (
    <Box sx={{
      position: 'relative',
    }}>
      {renderContent()}
    </Box>
  )

}
