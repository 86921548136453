
// @configs
import project from "@configs/project";

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// @snippets
import EpicGamesLogo from "@snippets/EpicGamesLogo";

export default function Partners() {

  const logotypes = [
    project.assets.logotypes.unrealEngine,
    project.assets.logotypes.aws,
    project.assets.logotypes.metaeditor,
    project.assets.logotypes.telesite,
    project.assets.logotypes.kokodi,
  ]

  return (
    <Box>
      <Typography variant="h3" sx={{
        mb: 10,
        textAlign: {
          xs: 'center',
          md: 'left',
        }
      }}>
        We partner with some of the best companies
      </Typography>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 20,
      }}>
        <Box sx={{
          width: '100%',
          flexGrow: 1,
          bgcolor: 'background.paper',
          borderRadius: 2,
          p: {
            xs: 5,
            md: 10,
          },

          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
          gap: 5,
        }}>
          {logotypes.map((logotype, index) => (
            <Box
              key={index}
              component="img"
              src={logotype}
              sx={{
                width: '100%',
                height: {
                  xs: 35,
                  md: 55,
                },
                opacity: .7,
              }} />
          ))}
        </Box>

        <Box>
          <EpicGamesLogo />
        </Box>
      </Box>

    </Box>
  )
}