import { useEffect } from "react";
import NextLink from "next/link";

// @configs
import project from "@configs/project";

// libs
import anime from 'animejs';

// mui
import { useTheme, Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { BoxProps } from "@mui/material";

// @components
import IconifyIcon from "@components/IconifyIcon";

// @data
import { unrealEngineSolutionsList } from '@data/unreal-engine'

// @modules
import { StemmerClient, RenderClientHtml } from "@modules/CrossLinker";


const Content = () => {

  const stremer = new StemmerClient()
  const htmlContent = `
  Discover the power of 3D metaverses powered by Unreal Engine and drive your business forward. Our team is equipped to develop creative 3D environments that are tailored to your individual needs, taking into account all elements such as visuals, user experience, interactivity, and more.
  `
  const content = stremer.stem('', htmlContent)

  return (
    <Box>
      <Typography variant="h1" gutterBottom>
        Unreal Engine Development
      </Typography>
      <Typography variant="body1" component="div">
        <RenderClientHtml>
          {content}
        </RenderClientHtml>
      </Typography>

      <Box sx={{
        pt: 15,
      }}>
        <Typography variant="h4" color="text.secondary"
          sx={{
            mb: 5,
          }}>
          Our Solutions
        </Typography>
        <Solutions />
      </Box>
    </Box>
  )
}


interface IconCardProps extends BoxProps {
  title: string;
  icon: string;
}

const IconCard = (props: IconCardProps) => {
  return (
    <Box
      className="unreal-card"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // bgcolor: 'background.default',
        border: '1px solid',
        borderColor: 'rgba(255,255,255,0.5)',
        width: 300,
        borderRadius: 2,
        ...props.sx,
      }}>
      <Box sx={{
        paddingTop: '100%',
      }} />
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'default',
      }}>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          {props.title}
        </Typography>
        <Box
          sx={{
            width: '60%',
          }}
          component="img"
          src={props.icon} />
      </Box>
    </Box>
  )
}

export default function UnrealEngine() {

  // hooks
  const theme: Theme = useTheme();


  useEffect(() => {
    animateCard()
  }, [])

  const animateCard = () => {
    anime({
      targets: `.unreal-card`,
      translateY: 30,
      scale: 1.05,
      easing: 'easeInOutQuad',
      direction: 'alternate',
      delay: anime.stagger(100),
      loop: true,
    });
  }

  return (
    <Box sx={{
      display: 'flex',
      gap: 80
    }}>
      <Content />
      <Box sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        position: 'relative',
        pb: '100px',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Box
          sx={{
            position: 'absolute',
            top: '100px',
            left: '-55%',
            zIndex: 2,
          }}>
          <IconCard
            title="Unreal Engine"
            icon={project.assets.logotypesCircle.unrealEngine}
            sx={{
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1), 0px 0px 20px 0px rgba(0,0,0,0.1),  0px 0px 40px 0px rgba(0,0,0,0.05)',
              bgcolor: theme.palette.grey[50],
              // zIndex: 2,
            }} />
        </Box>
        <IconCard
          title="Pixel Streaming"
          icon={project.assets.logotypesCircle.pixelStreaming}
          sx={{
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1), 0px 0px 10px 0px rgba(0,0,0,0.1)',
            bgcolor: theme.palette.grey[100],
            zIndex: 1,
          }} />
      </Box>
    </Box>
  )
}


const Solutions = () => {
  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: 3,
    }}>
      {unrealEngineSolutionsList.map((item, index) => (
        <Box
          key={index}
          component={NextLink}
          href={item.href}
          sx={{
            color: 'text.primary',
            textDecoration: 'none',

            borderRadius: 1,
            border: 'solid 1px',
            borderColor: 'divider',
            px: 4,
            py: 4,
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            cursor: 'pointer',
            transition: 'all .2s ease-in-out',
            '&:hover': {
              borderColor: 'primary.light',
            },
            '& svg': {
              fontSize: '1.5rem',
            }
          }}>
          <IconifyIcon icon={item.icon} />
          <Typography variant="h6">
            {item.title}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
