// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// snippets
import VideoBlock from './snippets/VideoBlock'

import CreateButton from './snippets/CreateButton'
import settings from './snippets/settings'


interface Props {
  title: string
  subtitle: string
}

export default function View(props: Props) {


  return (
    <Box>
      {/* <Box sx={{
        bgcolor: settings.bgColor(1),
        // mt: (theme: any) => `-${theme?.mixins.toolbar?.minHeight}px`,
        // height: (theme: any) => `${theme?.mixins.toolbar?.minHeight}px`,
        height: 30,
      }} /> */}
      <Box
        sx={{
          bgcolor: settings.bgColor(1),
          color: 'common.white',
          display: 'flex',
          flexDirection: 'column',
        }}>

        <Box sx={{
          position: 'relative',
          borderBottom: 'solid 1px rgba(255,255,255, .1)',
        }}>
          <Box sx={{
            paddingTop: '100%',
          }} />
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}>
            <VideoBlock />
          </Box>
        </Box>

        <Box sx={{
          py: 10,
          px: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          bgcolor: 'rgba(0,0,0,.2)',
        }}>
          <Typography component="h1" variant="h2" align="center" sx={{
            mb: 5
          }}>
            {props.title}
          </Typography>

          <Typography variant="body1" align="center">
            {props.subtitle}
          </Typography>

          <Box sx={{
            pt: 10,
            '& > a': {
              width: '100%',
            }
          }}>
            <CreateButton />
          </Box>

        </Box>

      </Box>

    </Box>
  )
}
