
// configs
import { cfg, stemDictionary } from './configs'

// libs
import seedrandom from 'seedrandom';

// types
import type { StemmerOptions } from './types'

class StemmerClient {
  public stem(resolvedUrl: string, text: string, options?: StemmerOptions) {
    return processText(resolvedUrl, text, options)
  }
}

export default StemmerClient;



// Function to process the input text
function processText(resolvedUrl: string, text: string, options?: StemmerOptions) {

  // Remove HTML tags from the text
  const textPlain = removeHtmlTags(text);

  // Create a map of words to links
  const wordMap: { [key: string]: string } = {};

  // Tokenize the text into words
  const tokens = textPlain.split(/\s+/);

  if (!tokens) {
    return text;
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i].toLowerCase().trim();
    const word = tokens[i].trim();



    let dictionary = stemDictionary;
    if (options?.category) {
      dictionary = stemDictionary.filter(item => {
        if (item.category === 'default') {
          return true;
        }
        if (item.category === options.category) {
          return true;
        }
      })
    }

    // Check each object in your dictionary (replace 'yourDictionary' with your own dictionary)
    for (const entry of dictionary) {

      const found = entry.keywords.find(keyword => keyword.toLowerCase().trim() === token.toLowerCase().trim());

      if (found) {
        // Found a keyword, add it to the word map with its corresponding link
        const link = entry.link;

        // Check neighboring words (words to the left and right of the current word)
        let leftNeighbor = i > 0 ? tokens[i - 1] : null;
        let rightNeighbor = i < tokens.length - 1 ? tokens[i + 1] : null;

        // Remove trailing punctuation from the left neighbor
        while (leftNeighbor && /[.,!?;:]/.test(leftNeighbor.charAt(leftNeighbor.length - 1))) {
          leftNeighbor = leftNeighbor.slice(0, -1);
        }

        // Remove leading punctuation from the right neighbor
        while (rightNeighbor && /[.,!?;:]/.test(rightNeighbor.charAt(0))) {
          rightNeighbor = rightNeighbor.slice(1);
        }

        // Combine the neighbors and the current word to form the full word
        const fullWord = `${leftNeighbor ? leftNeighbor + ' ' : ''}${word}${rightNeighbor ? ' ' + rightNeighbor : ''}`;

        wordMap[fullWord] = link;
      }
    }
  }

  // Replace words with links in the original text
  const processedText = replaceWordsWithLinks(resolvedUrl, text, wordMap);

  return processedText;
}

function removeHtmlTags(html: string) {
  const regex = /<\/?[^>]+(>|$)/g;
  return html.replace(regex, '');
}

export const replaceWordsWithLinks = (resolvedUrl: string, text: string, wordMap: { [key: string]: string }): string => {

  const maxSimilarLinks = cfg.maxSimilarLinks;
  const charCount = text.length;
  let maxReplacements = Math.floor(charCount / cfg.density) + 1; // +1 чтобы гарантировать минимум 1 замену

  // If the text is short, only replace one word
  if (charCount < cfg.density) {
    maxReplacements = 1;
  }

  let result = text;

  // Create a seeded random number generator
  const rng = seedrandom('42');

  // Retrieve the words from the word map
  const words = Object.keys(wordMap);

  // Shuffle the words
  words.sort(() => rng() - 0.5);

  for (let i = 0; i < words.length; i++) {
    if (maxReplacements <= 0) {
      break;
    }

    const word = words[i];
    const link = wordMap[word];
    const regex = new RegExp(`\\b${word}\\b`, 'gi');

    if (resolvedUrl && link.includes(resolvedUrl)) {
      continue;
    }

    // split by breakline
    const resultParts = result.split('\n');

    for (let i = 0; i < resultParts.length; i++) {

      // Count links in result
      if (result.split(new RegExp(link, 'gi')).length - 1 >= maxSimilarLinks) {
        continue;
      }

      resultParts[i] = resultParts[i].replace(regex, `<a href="${link}">${word}</a>`);
      result = resultParts.join('\n');
    }


    if (result.includes(link)) {
      maxReplacements--;
    }
  }

  return result;
}


