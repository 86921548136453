
// mui
import { keyframes } from '@mui/material';
import { Container, Typography } from "@mui/material";
import Box from '@mui/material/Box';

// @components
import TypeEffect from "@components/TypeEffect";

// snippets
import CreateButton from './snippets/CreateButton'
import VideoBlock from './snippets/VideoBlock'
import settings from './snippets/settings'



interface Props {
  title: string
  subtitle: string
}

export default function View(props: Props) {

  return (
    <Box sx={{
      bgcolor: settings.bgColor(1),
      color: 'common.white',
    }}>

      <Container
        maxWidth="xl"
        sx={{
          px: {
            xs: 0,
            sm: 0,
            md: 10,
            lg: 10,
            xl: 0,
          },
        }}>

        <Box
          sx={{
            position: 'relative',
            minHeight: {
              xs: 'auto',
              sm: '70vh',
              lg: '100vh',
            },
          }}>

          <VideoBlock />

          <Box sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            pt: (theme: any) => theme.mixins.toolbar.minHeight + 'px',
            width: {
              xs: '100%',
              md: '40%',
            },
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            zIndex: 1,
          }}>
            <ContentBlock {...props} />
          </Box>
        </Box>

      </Container>

    </Box>
  )
}



const ContentBlock = ({ title, subtitle }: Props) => {

  const titleArray = title.split('\n')

  return (
    <Box>
      <Typography component="h1" variant="inherit" sx={{
        color: 'common.white',
        fontSize: {
          xs: '2.0rem',
          sm: '2.5rem',
          md: '3.0rem',
          lg: '3.5rem',
          xl: '5rem',
        },
        fontWeight: 700,
        lineHeight: '110%',
        mb: 5,
        whiteSpace: 'nowrap',
        opacity: 0,
        animation: `${slideFromLeft} 1s ease forwards`,
        // animationDelay: '3.5s',
        textShadow: '0 0 5px rgba(0,0,0,0.3)',
      }}>
        We develop
        <TypeEffect
          startDelay={0}
          wrapper='div'
          speed={30}
          repeat={Infinity}
          sequenceSSR={titleArray[0]}
          sequence={[
            'AI & ML',
            2000,
            '3D & Metaverses',
            2000,
            'iGaming',
            2000,
            'Crypto & DeFi',
            2000,
            'PaaS & SaaS',
            2000,
          ]}
        />

      </Typography>
      <Typography component="p" sx={{
        textShadow: '0 0 5px rgba(0,0,0,0.3)',
        fontSize: {
          lg: '1.5rem',
        },
      }}>
        {subtitle}
      </Typography>

      <Box sx={{
        pt: 20
      }}>
        <CreateButton />
      </Box>

    </Box>
  )
}



const slideFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
