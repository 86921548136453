import { useGlobalContext } from "@core/context";

// @snippets
import RenderHtml from "@snippets/RenderHtml";


export const RenderServerHtml = () => {
  const context = useGlobalContext()

  return (
    <RenderHtml>
      {context.pageProps?.pageData}
    </RenderHtml>
  )
}

export const RenderClientHtml = ({ children }: { children: any }) => {
  return (
    <RenderHtml>
      {children}
    </RenderHtml>
  )
}