
// @configs
import project from "@configs/project";

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// @components
import IconifyIcon from "@components/IconifyIcon";
import { ButtonLink } from "@components/Link";
import routes from "@configs/routes";


export default function Forbes() {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      bgcolor: 'customColors.overlay',
      borderRadius: 2,
      p: {
        xs: 5,
        md: 10,
      },
      gap: 10,
    }}>
      <Box sx={{
        width: {
          xs: '100%',
          md: '50%',
        },
        flexShrink: 0,
      }}>
        <Box sx={{
          position: 'relative',
          lineHeight: 0,
          borderRadius: 2,
          overflow: 'hidden',
        }}>
          <Box
            component="a"
            href={project.links.forbesYoutube}
            target="_blank"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(0,0,0,0.1)',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'background-color 0.2s ease-in-out',

              cursor: 'pointer',

              '& svg': {
                transition: 'transform 0.2s ease-in-out',
              },
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.4)',
                '& svg': {
                  transform: 'scale(1.2)',
                }
              }
            }}>
            <IconifyIcon
              icon="mdi:youtube" color="#fff" width={80} height={80} />
          </Box>
          <Box
            component="img"
            src={project.assets.images.forbes}
            sx={{
              width: '100%',
              height: 'auto',
            }} />
        </Box>
      </Box>
      <Box>
        <Typography variant="h5" sx={{ mb: 5 }}>
          Unrealos, featured in Forbes, pioneers web service and 3D Unreal Engine solutions.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          With over two decades of experience, we excel in crafting cutting-edge technologies that enhance business processes.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          As an official partner of Epic Games, we focus on PaaS and SaaS development, AI integration, and captivating UI design.
        </Typography>
        <Typography variant="subtitle1">
          Our global reach and strategic partnerships with industry giants ensure your success in international markets. Let's unlock your business potential together!
        </Typography>
        <Box sx={{
          mt: 5
        }}>
          <ButtonLink
            endIcon={<ArrowForwardIcon />}
            variant="outlined"
            href={routes.PAGES.ABOUT}
          >
            Read More
          </ButtonLink>
        </Box>
      </Box>
    </Box>
  )
}