// @core
import useTranslate, { tDict } from "@core/i18n/useTranslate";

// @configs
import routes from "@configs/routes";

// icons
import LoopIcon from '@mui/icons-material/Loop';

// @components
import { ButtonLink } from "@components/Link";


export default function CreateButton() {

  // hooks
  const { t } = useTranslate('main');

  return (
    <ButtonLink
      href={routes.PAGES.CONTACTS}
      variant="contained"
      color="inherit"
      sx={{
        borderRadius: 100,
        pointerEvents: 'all',
        fontSize: {
          xs: '1rem',
          sm: '1.25rem',
        },
        p: {
          xs: '0.7rem 1rem',
          sm: '1rem 2rem',
        },
        bgcolor: 'common.white',
        color: 'primary.main',
        '&:hover': {
          bgcolor: 'common.white',
          color: 'primary.main',
        }
      }}>
      Request a quote
    </ButtonLink>
  )
}