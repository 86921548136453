import { useState } from "react";

// @configs
import routes from "@configs/routes";

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { Collapse } from '@mui/material';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// @components
import { ButtonLink } from "@components/Link";



export default function Expertise() {

  const services = [
    {
      title: 'Custom Software \nDevelopment',
      description: 'Create custom software tailored for your unique needs, including front-end, and core back-end technology.',
      icon: 'carbon:code',
    },
    {
      title: 'QA and \nTesting',
      description: 'Make your technology bulletproof, with manual and automated testing.',
      icon: 'file-icons:test-generic',
    },
    {
      title: 'AI and \nData Science',
      description: 'Use leading AI, machine learning, and data engineering technologies to unlock business value.',
      icon: 'fluent:brain-circuit-20-regular',
    },
    {
      title: 'Mobile App \nDevelopment',
      description: 'Build performant, scalable, and secure mobile applications for iOS and Android devices.',
      icon: 'carbon:application-mobile',
    },
    {
      title: 'UX/UI \nDesign',
      description: 'Create beautiful, pixel-perfect, and easy-to-use designs that delight your end users.',
      icon: 'icon-park-outline:graphic-design',
    },
    {
      title: 'Platform and \nInfrastructure',
      description: 'Ensure applications are secure, fault tolerant and highly available with our DevOps and Security engineers.',
      icon: 'carbon:ibm-cloud-virtual-server-classic',
    },
  ]
  const servicesColumn1 = services.slice(0, services.length / 2)
  const servicesColumn2 = services.slice(services.length / 2, services.length)

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        alignItems: {
          xs: 'flex-start',
          sm: 'center',
        },
        justifyContent: {
          xs: 'flex-start',
          sm: 'space-between',
        },
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        py: 10,
        gap: 5,
      }}>
        <Box sx={{
          maxWidth: {
            xs: '100%',
            sm: 600,
          },
        }}>
          <Typography variant="h1" gutterBottom>
            Areas of Expertise.
          </Typography>
          <Typography variant="body1">
            As a software development services company, we have a wide range of expertise to provide complete solutions for our clients.
          </Typography>
        </Box>
        <Box>
          <ButtonLink
            fullWidth
            href={routes.PAGES.CONTACTS}
            endIcon={<ArrowForwardIcon />}
            variant="outlined"
            color="inherit">
            Ask a question
          </ButtonLink>
        </Box>
      </Box>

      <Box sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: '1fr 1fr',
        },
        columnGap: 20,
      }}>
        <Box>
          {servicesColumn1.map((service, index) => (
            <Box key={index}>
              <AccordionItem
                title={service.title}
                description={service.description}
                icon={service.icon}
              />
            </Box>
          ))}
        </Box>
        <Box>
          {servicesColumn2.map((service, index) => (
            <Box key={index}>
              <AccordionItem
                title={service.title}
                description={service.description}
                icon={service.icon}
              />
            </Box>
          ))}
        </Box>
      </Box>

    </Box>
  );
}

interface AccordionItemProps {
  title: string
  description: string
  icon: string
}
const AccordionItem = ({ title, description, icon }: AccordionItemProps) => {

  // states
  const [expanded, setExpanded] = useState(false)

  return (
    <Box sx={{
      borderBottom: '1px solid',
      borderColor: 'divider',
      userSelect: 'none',
    }}>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          transition: 'opacity 0.3s ease',
          py: 5,
          '&:hover': {
            opacity: 0.8,
          }
        }}
        onClick={() => setExpanded(!expanded)}>
        <Typography variant="h5">
          {title}
        </Typography>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'text.secondary',
          transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s',
        }}>
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </Box>
      </Box>
      <Collapse in={expanded}>
        <Box sx={{
          pb: 5,
        }}>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  )
}